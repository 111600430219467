// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-dostawa-tego-samego-dnia-js": () => import("./../../../src/pages/dostawa-tego-samego-dnia.js" /* webpackChunkName: "component---src-pages-dostawa-tego-samego-dnia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logistyka-kontraktowa-js": () => import("./../../../src/pages/logistyka-kontraktowa.js" /* webpackChunkName: "component---src-pages-logistyka-kontraktowa-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatności.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-transport-dedykowany-js": () => import("./../../../src/pages/transport-dedykowany.js" /* webpackChunkName: "component---src-pages-transport-dedykowany-js" */),
  "component---src-pages-transport-wahadlowy-js": () => import("./../../../src/pages/transport-wahadłowy.js" /* webpackChunkName: "component---src-pages-transport-wahadlowy-js" */)
}

