import React from 'react'

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const cookies =
  typeof localStorage !== 'undefined' && localStorage.getItem('cookies')

const initialState = {
  isLoading: true,
  isCookieBarVisible: cookies === 'false' ? false : true,
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: action.value,
      }
    }
    case 'SET_COOKIES_BAR_VISIBLE': {
      return {
        ...state,
        isCookieBarVisible: action.value,
      }
    }
    default:
      throw new Error('Bad Action Type')
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
